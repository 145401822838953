export enum Packages {
  REGISTER = 'cadastre',
  CLIENT = 'client',
  SUPPLIER = 'supplier',
  PRODUCT = 'products',
  SERVICES = 'services',
  REGISTER_OTHERS = 'cadastre-others',
  CONTACTS = 'contacts',
  GROUP = 'group',
  UNIT_OF_MEASURE = 'unit-of-measure',
  FINANCE = 'finance',
  TRANSCATION = 'transaction',
  REVENUE = 'revenue',
  EXPENSE = 'expense',
  TRANSFER = 'transfer',
  FINANCE_OTHERS = 'finance-others',
  ACCOUNT = 'account',
  GOALS = 'goals',
  CATEGORY = 'category',
  INSTALLMENT = 'installment',
  FLAG = 'flag',
  CARD_ADMINISTRATOR = 'card-administrator',
  INVENTORY = 'inventory',
  PURCHASE = 'purchase',
  ESTIMATE = 'estimate',
  SALE_ORDER = 'sale',
  OS = 'os',
  PURHASE_ORDER = 'purchase-order',
  MANAGERIAL = 'managerial',
  INVENTORY_OTHERS = 'inventory-others',
  INVENTORY_CHECK = 'inventory-check',
  FISCAL = 'fiscal',
  FISCAL_CONFIGURATION = 'configuration',
  FISCAL_REPORTS = 'fiscal-reports',
  NFE = 'nfe',
  NFCE = 'nfce',
  PDV = 'pdv',
  MANIFEST = 'manifesto',
  MDFE = 'mdfe',
  NFSE = 'nfse',
  FISCAL_OTHERS = 'fiscal-others',
  NATURE = 'natureza',
  TRIBUTATION = 'tributacao',
  DEVICE = 'device',
  CFOP_PARAMETERIZATION = 'cfopParameterization',
  PARAMETRIZATION = 'parametrizacao',
  SHIPAY = 'shipay',
  DOCUMENTS_CHECKOUT = 'documents-checkout',
  BENEFICIO = 'beneficio',
  REPORTS = 'reports',
  FINANCE_REPORTS = 'finance-reports',
  INVENTORY_REPORTS = 'inventory-reports',
  PERSON_REPORTS = 'person-reports',
  APPLICATION_REPORTS = 'application-reports',
  HISTORY_REPORTS = 'history-reports',
  REGISTER_REPORTS = 'register-reports',
  BANKSLIP = 'bankslip',
  RECEIPT = 'receipt',
  REPLICAR = 'replicar',
  WHATSAPP = 'whatsapp',
  STONE = 'stone',
  SINTEGRA = 'sintegra',
  COMMISSION = 'commission',
  VARIATION = 'variation',
  PRICE_TABLE = 'price-table',
  KIT = 'kit',
  INTEGRATOR = 'integrator',
  APPS_CLOUD_NFCE = 'apps-cloud-nfce',
  ZETA_DESK = 'zeta-desk',
  INTEGRATIONS = 'integrations',
  ECOMMERCE = 'ecommerce',
  MERCADOLIVRE = 'mercadolivre',
  ZHUB = 'zhub',
  DAV = 'dav',
  DOCUMENTS = 'documents',
  DOCUMENTS_CONFIGURATION = 'documents-configuration',
  DOCUMENTS_REPORTS = 'documents-reports',
  DASHBOARD = 'Dashboard',
  QUICK_ACCESS = 'quick-access',
  STOCK = 'stock',
  USER = 'user',
  ZPOS = 'zpos',
  CONVERSATION_FACTOR = 'conversation-factor',
  BANK_SLIP = 'bank-slip',
  SPED = 'sped',
  INVENTORY_TALBE = 'inventory-table',
  CHART_OF_ACCOUNTS = 'chart-of-accounts',
  FINX = 'finx',
  CONEXAO_ITAU = 'conexao-itau',
  CARGO_MANIFEST = 'cargo-manifest',
}
